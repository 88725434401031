import { API } from '../../api/api.types';

export enum TICKETTYPE_FLAG {
  UPDATE = 'UPDATE',
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  RESET = 'RESET',
}

export interface PerformanceRespDtoExtended
  extends API.PerformanceWithPromotionImageDto {
  ticketHeroImageId?: number;
}

export interface EventRespDtoExtended extends API.EventRespDto {
  code?: string;
  tmEventLocalId?: number;
  cmEventLocalId?: number;
  performanceId: number;
  venueId?: number;
  performance: PerformanceRespDtoExtended;
  copied?: boolean;
}

export interface TransferPolicy {
  creationMethod: string;
  priceGain: boolean;
  moveAsIs: boolean;
  simpleTransfer: boolean;
  antiTouting: boolean;
  agentTransfer: boolean;
  refund: boolean;
  blindResale: boolean;
  directResale: boolean;
}

export interface TransferPolicyValues {
  transferPolicy: TransferPolicy;
  priceGainMax?: number;
}

export interface NotificationType {
  sendBefore: number;
  type: string;
}

export interface AlertPolicy {
  eventAlert: boolean;
  alertText: string;
}

export interface ActivationPolicy {
  gateOpen: boolean;
  gateImage: boolean;
  publicActivationCode: boolean;
}

export interface GateStaffPolicy {
  activationCode: boolean;
  invalidationCode: boolean;
  randomSpotChecks: boolean;
  targetedSpotChecks: boolean;
}

export interface GateStaffPolicyValues {
  gateStaffPolicy: GateStaffPolicy;
  invalidationCode?: number;
  password?: string;
  spotCheckPercentage?: number;
}
export interface EventStore {
  eventLocal: EventRespDtoExtended;
  eventOrigin: EventRespDtoExtended;
  // changed: Computed<EventStore, () => boolean>;
}

export interface CreateTicketTypeObj extends API.TicketType {
  flag?: TICKETTYPE_FLAG;
  issuedTickets: number;
  validatedTickets: number;
  sectionId?: number;
}

export interface UpdateTicketTypeObj extends API.TicketTypeUpdateDto {
  flag?: TICKETTYPE_FLAG;
  issuedTickets: number;
  validatedTickets: number;
  sectionId?: number;
}

export interface SetToDeletePayload {
  id: number;
}

export type SetToUpdatePayload = CreateTicketTypeObj | UpdateTicketTypeObj;

export interface UpdateProcessableTicketType {
  ticketType: UpdateTicketTypeObj;
}
export interface CreateProcessableTicketType {
  ticketType: CreateTicketTypeObj;
}
export interface DeleteProcessableTicketType {
  ticketType: UpdateTicketTypeObj;
}

export interface IupdateTicketType {
  id?: number | null;
  ticketTypeId?: number | null;
  flagValue: TICKETTYPE_FLAG;
}
export type ProcessableTicketType = UpdateTicketTypeObj | CreateTicketTypeObj;
