import './styles.css';

import {
  Table,
  TableBody,
  TablePagination,
  TableRow,
  Paper,
  TableCell,
} from '@mui/material';
import React, { Fragment, useState } from 'react';
import GridHead from '../../core/grid/gridHead';
import Loading from '../../core/loading';
import { PreImportTicketExtended } from '../ticketImportProcess/types';
import TicketValidationTableContent from '../ticketValidationTableContent';

const TicketValidationTable: React.FunctionComponent<{
  validationData: PreImportTicketExtended[];
  testId: string;
}> = ({ validationData, testId }) => {
  const [page, setPage] = useState(1);
  const [orderColumn, setOrderColumn] = useState('ticketId');
  const [orderDirection, setOrderDirection] = useState<'desc' | 'asc' | false>(
    'desc',
  );
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const headerList = [
    { id: 'rowId', label: 'Row N.', sort: false },
    { id: 'ownerOrAgent', label: 'Owner- / Agent name', sort: false },
    { id: 'event', label: 'Event', sort: false },
    { id: 'ticketType', label: 'Ticket Type' },
    { id: 'customFields', label: 'Custom Fields' },
    { id: 'keys', label: 'Keys' },
    { id: 'validationStatus', label: 'Error(s)' },
  ];

  const loading = false;
  const error = false;

  const changeOrder = (property: keyof any) => {
    if (property.toString() === orderColumn) {
      if (orderDirection === 'desc') {
        setOrderDirection('asc');
      } else {
        setOrderDirection('desc');
      }
    } else {
      setOrderColumn(property.toString());
      setOrderDirection('asc');
    }
  };

  const paginationSlice = (validationData: PreImportTicketExtended[]) => {
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = page * rowsPerPage;

    const slicedData = validationData.slice(startIndex, endIndex);

    return slicedData;
  };

  const data = paginationSlice(validationData);

  return (
    <Fragment>
      {!loading && (
        <Paper className="table-overflow">
          <Table>
            <GridHead
              list={headerList}
              orderColumn={orderColumn}
              orderDirection={orderDirection}
              changeOrder={changeOrder}
              testId={testId}
            />
            <TableBody className="body">
              {data?.map((preTicketData, index: number) => (
                <TicketValidationTableContent
                  key={preTicketData.rowNumber}
                  preTicket={{ ...preTicketData }}
                />
              ))}
            </TableBody>
            {!loading && validationData && validationData.length === 0 && (
              <TableBody className="body">
                <TableRow>
                  <TableCell colSpan={headerList.length}>
                    <div className="alert-ticket-table alert-warning-ticket-table">
                      <strong>
                        <i className="fa fa-exclamation-triangle fa-fw fa-lg"></i>{' '}
                        No data available
                      </strong>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {error && (
              <TableBody className="body">
                <TableRow>
                  <TableCell colSpan={headerList.length}>
                    <div className="alert-ticket-table alert-warning-ticket-table">
                      <strong>
                        <i className="fa fa-exclamation-triangle fa-fw fa-lg"></i>
                        Error getting ticket data
                      </strong>
                    </div>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            <TableBody>
              <TableRow>
                <TablePagination
                  colSpan={headerList.length + 1}
                  rowsPerPageOptions={[5, 25, 50, 100]}
                  count={validationData.length}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  data-testid={`${testId}Table-paginationContainer`}
                />
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      )}

      {loading && <Loading />}
    </Fragment>
  );
};

export default TicketValidationTable;
