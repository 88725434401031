import Axios from 'axios';
import { configure, makeUseAxios } from 'axios-hooks';
import { logout } from './loginApi/user';
import Swal from 'sweetalert2';

Axios.defaults.baseURL = (
  process.env.REACT_APP_API_ROOT || 'http://localhost:4000'
).replace(/\/+$/, '');

Axios.defaults.headers.post['Content-Type'] = 'application/json';
const axios = Axios.create();

axios.interceptors.request.use(
  async (config) => {
    const date = Math.floor(new Date().getTime() / 1000);
    config.headers['time'] = date;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

configure({ axios });

let axiosResInterceptor: any = null;

export const configureApiToken = (token: string) => {
  // Every time you configure a new token it adds an interceptor to an array instead of replacing it
  // We most eject the interceptor from the array before creating the new or else it will use both interceptors
  // But the first one configured will always be last executed  (Uses the token of the first user after app loaded for every request)
  if (!!axiosResInterceptor || axiosResInterceptor === 0) {
    axios.interceptors.response.eject(axiosResInterceptor);
  }
  axios.defaults.headers.common['authorization'] = token;
  axios.defaults.params = {};

  axiosResInterceptor = axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        Swal.fire({
          title: `Session expired`,
          text: `You will be redirected to the login page`,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: 'red',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        }).then(() => {
          logout();
          window.location.reload();
        });
      }

      return Promise.reject(error);
    },
  );

  configure({ axios });
};

export const useAxiosLoop = () => {
  return makeUseAxios({
    axios: axios,
    defaultOptions: {
      manual: false,
      useCache: true,
      ssr: true,
      autoCancel: false,
    },
  });
};
