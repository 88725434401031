const ValidateTicket = (
  id: number | undefined,
  data: object | null,
): object => {
  return {
    method: 'POST',
    url: `/api/Accounts/${id}/ticketImport/validate`,
    data: data,
  };
};

const ConfirmImport = (id: number | undefined, data: object | null): object => {
  return {
    method: 'POST',
    url: `/api/Accounts/${id}/ticketImport/confirm`,
    data: data,
  };
};

const GetImports = (id: number | undefined): object => {
  return { method: 'GET', url: `/api/Accounts/${id}/ticketImport/get` };
};

const GetImportedFile = (
  id: number | undefined,
  fileId: number | undefined,
): object => {
  return {
    method: 'GET',
    url: `/api/Accounts/${id}/ticketImport/${fileId}/download`,
  };
};

const GetTicketExportData = (
  id: number | undefined,
  ticketTypeIds: Array<number> | undefined,
): object => {
  const parameter: { [k: string]: any } = {};
  if (ticketTypeIds) {
    parameter.ticketTypeIds = ticketTypeIds;
  }
  return {
    method: 'get',
    url: `/api/Accounts/${id}/ticketImport/ticketDataExport`,
    params: parameter,
  };
};

export {
  ValidateTicket,
  ConfirmImport,
  GetImports,
  GetImportedFile,
  GetTicketExportData,
};
