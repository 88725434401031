import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TablePagination,
  TextField,
  Typography,
} from '@mui/material';
import useAxios from 'axios-hooks';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConfirmImport } from '../../../api/ticketApi/ticketImport';
import { useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import Loading from '../../core/loading';
import { TicketImportSummarization, TicketTypeSummarization } from './types';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { map } from 'lodash';
import { isValidEmail } from '../../../core/utils/isValidEmail';
import Swal from 'sweetalert2';

export const ProcessConfirm: React.FC<{
  updateRequest: boolean;
  fileId: number | null;
  importSummarization: TicketImportSummarization;
}> = ({ updateRequest, fileId, importSummarization }) => {
  const account = useAppSelector((state: RootState) => state.user.account);
  const userEmail = useAppSelector((state: RootState) => state.user.userEmail);
  const [expandedParent, setExpandedParent] = useState(false);
  const [expandedChild, setExpandedChild] = useState<string | null>(null);
  const navigate = useNavigate();
  const [onLoading, setOnLoading] = useState<boolean>(false);
  const [emailNotification, setEmailNotification] = useState<boolean>(false);
  const [email, setEmail] = useState<string>(userEmail);

  const accountId = account?.accountId;
  const [, refetch] = useAxios<{ isSuccess: boolean }>(
    ConfirmImport(accountId, null),
    { manual: true },
  );

  const testId = 'ticketimport-confirm';

  const handleConfirm = async () => {
    setOnLoading(true);

    try {
      const { data } = await refetch(
        ConfirmImport(accountId, { fileId, email, updateRequest }),
      );

      if (!data.isSuccess) {
        Swal.fire({
          title: `Ticket Importer Error`,
          text: `Failed to upload CSV/Excel file. Double check that all the Foreign Ticket IDs & QR codes are unique.`,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
        }).then(() => {
          navigate('/tickets/import');
        });
      } else {
        navigate('/tickets/import');
      }
    } catch (error) {
      Swal.fire({
        title: `Ticket Importer Error`,
        text: `Failed to upload CSV/Excel file. Double check that all the Foreign Ticket IDs & QR codes are unique.`,
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'OK',
      }).then(() => {
        navigate('/tickets/import');
      });
    }
  };

  const isValidEmailAddress = isValidEmail(email);

  const ExtraInfoAccordion: React.FC<{
    ticket: TicketTypeSummarization;
    index: string;
    expandedChild: string | null;
    setExpandedChild: (value: string | null) => void;
    testId: string;
  }> = ({ ticket, index, expandedChild, setExpandedChild, testId }) => {
    return (
      <Accordion
        key={index}
        expanded={expandedChild === index}
        onChange={() =>
          setExpandedChild(expandedChild === index ? null : index)
        }
      >
        <AccordionSummary
          data-testid={`${testId}Page-child-accordion-summary`}
          expandIcon={<ExpandMoreOutlined />}
          aria-controls={`${index}-content`}
          id={`${index}-header`}
        >
          <Typography
            data-testid={`${testId}Page-child-accordion-details-text`}
          >
            <strong>{ticket.ticketTypeName}</strong> ({ticket.ticketTypeCode}) -{' '}
            {ticket.ticketCount} ticket(s)
          </Typography>
        </AccordionSummary>
        <AccordionDetails data-testid={`${testId}Page-child-accordion-details`}>
          <UpdatedTicketDetails updates={ticket.updates} />
        </AccordionDetails>
      </Accordion>
    );
  };

  const UpdatedTicketDetails: React.FC<{
    updates: TicketTypeSummarization['updates'];
  }> = ({ updates }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const count = Object.keys(updates).length;

    const paginatedItems = Object.keys(updates).slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage,
    );

    const handleChangePage = (event: unknown, newPage: number) => {
      setCurrentPage(newPage);
    };

    const handleItemsPerPageChange = (event: { target: { value: any } }) => {
      setItemsPerPage(Number(event.target.value));
      setCurrentPage(0);
    };

    return (
      <Grid container>
        <Grid item xs={12}>
          <strong>Updated Tickets</strong>
          {paginatedItems.map((item) => (
            <p key={item}>
              Foreign Ticket ID:<strong> {item}</strong>
              <p
                style={{
                  border: '2px solid #ddd',
                  borderRadius: '12px',
                  padding: '6px',
                  backgroundColor: '#f9f9f9',
                }}
              >
                <span>{updates[item].join(', ')}</span>
              </p>
            </p>
          ))}
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="right" alignItems="right">
            <Grid item>
              <TablePagination
                rowsPerPageOptions={[5, 25, 50, 100]}
                count={count || 0}
                rowsPerPage={itemsPerPage}
                page={currentPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleItemsPerPageChange}
                data-testid={`${testId}confirm-paginationContainer`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const getSummarization = () => {
    return map(importSummarization, (item, index) => {
      return (
        <Accordion
          expanded={expandedParent}
          onChange={() => setExpandedParent(!expandedParent)}
        >
          <AccordionSummary
            data-testid={`${testId}Page-accordion-summary`}
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="parent-content"
            id="parent-header"
          >
            <Typography data-testid={`${testId}Page-accordion-summary-text`}>
              <strong>{item.ticketCount} ticket(s)</strong>{' '}
              {updateRequest ? 'will be updated' : 'will be created'} for the
              following event: <strong>{item.eventName}</strong> (
              {item.eventCode})
            </Typography>
          </AccordionSummary>
          <AccordionDetails data-testid={`${testId}Page-accordion-details`}>
            {map(item.ticketTypes, (ticket, index) => {
              if (updateRequest) {
                return (
                  <ExtraInfoAccordion
                    key={index}
                    ticket={ticket}
                    index={index}
                    expandedChild={expandedChild}
                    setExpandedChild={setExpandedChild}
                    testId={testId}
                  />
                );
              } else {
                return (
                  <Typography
                    data-testid={`${testId}Page-accordion-details-text`}
                  >
                    <strong>{ticket.ticketTypeName}</strong> (
                    {ticket.ticketTypeCode}) - {ticket.ticketCount} ticket(s)
                  </Typography>
                );
              }
            })}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <Grid container justifyContent={'center'} mt={12}>
      {onLoading && <Loading />}
      {!onLoading && (
        <Grid data-testid={`${testId}Page-grid-summary`} item xs={12} lg={6}>
          {getSummarization()}
        </Grid>
      )}
      <Grid
        data-testid={`${testId}Page-email-grid`}
        container
        justifyContent={'center'}
        spacing={2}
        mt={3}
      >
        {!onLoading && (
          <>
            <Grid item xs={12} lg={6}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      data-testid={`${testId}Page-email-checkbox`}
                      onChange={(e) => {
                        setEmailNotification(e.target.checked === true);
                      }}
                    />
                  }
                  label="I want email notification when the process is completed"
                />
              </FormGroup>
              {emailNotification && (
                <TextField
                  data-testid={`${testId}Page-email`}
                  error={!isValidEmailAddress}
                  helperText={
                    !isValidEmailAddress ? 'Invalid Email Address' : ''
                  }
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Grid container justifyContent={'center'} spacing={2} mt={6}>
        <Grid item xs={12} lg={3}>
          <Button
            onClick={() => {
              navigate('/tickets/import');
            }}
            className="textButton"
            color="primary"
            disabled={onLoading}
            fullWidth={true}
            variant="contained"
            data-testid={`${testId}Page-restart-Button`}
          >
            Restart
          </Button>
        </Grid>
        <Grid item xs={12} lg={3}>
          <Button
            onClick={handleConfirm}
            className="textButton"
            color="success"
            disabled={onLoading || (emailNotification && !isValidEmailAddress)}
            fullWidth={true}
            variant="contained"
            data-testid={`${testId}Page-confirm-Button`}
          >
            Confirm & start import
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
