import { Grid } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { ProcessConfirm } from './processConfirm';
import { ProcessUpload } from './processUpload';
import { ProcessValidation } from './processValidation';
import { PreImportTicketExtended, TicketImportSummarization } from './types';
import CustomStepper from '../../core/stepper';
import useAxios from 'axios-hooks';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { RootState } from '../../../store/store';
import { GetNotificationsSettingsConfig } from '../../../api/notificationsApi/notifications';
import { notificationAlert } from '../../../core/utils/alerts/notificationAlerts';
const testId = 'ticket-import-process';

const TicketImportProcessPage: React.FunctionComponent = () => {
  const accountId = useAppSelector(
    (state: RootState) => state.user.account?.accountId,
  );
  const navigate = useNavigate();
  const [processState, setProcessState] = useState(0);
  const [validationData, setValidationData] = useState<
    PreImportTicketExtended[]
  >([]);
  const [updateRequest, setIsUpdate] = useState<boolean>(false);
  const [importSummarization, setImportSummarization] =
    useState<TicketImportSummarization>({});
  const [fileId, setFileId] = useState<number | null>(null);

  const steps = ['Upload file', 'Validate', 'Confirm'];

  const [notificationsSettingsConfig] = useAxios(
    GetNotificationsSettingsConfig(accountId),
    { manual: !accountId },
  );

  useEffect(() => {
    const { data: config } = notificationsSettingsConfig;
    if (config) {
      const title = 'Ticket Importer Error';
      const html = `Ticket Importer is currently unavailable due to a misconfiguration in the notification system. <a href="mailto:support@tixserve.com">Contact Tixserve support</a> for assistance.`;
      notificationAlert({
        config,
        title,
        html,
        navTo: navigate,
        link: '/tickets',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsSettingsConfig]);

  return (
    <Fragment>
      <Grid container justifyContent={'center'} mt={2}>
        <Grid item xs={12} lg={12}>
          <CustomStepper
            testId={testId}
            steps={steps}
            activeStep={processState}
          />
        </Grid>
      </Grid>
      <Grid></Grid>
      {processState === 0 && (
        <ProcessUpload
          setImportSummarization={setImportSummarization}
          setProcessState={setProcessState}
          setValidationData={setValidationData}
          setIsUpdate={setIsUpdate}
          setFileId={setFileId}
        />
      )}
      {processState === 1 && (
        <ProcessValidation validationData={validationData} />
      )}
      {processState === 2 && (
        <ProcessConfirm
          updateRequest={updateRequest}
          importSummarization={importSummarization}
          fileId={fileId}
        ></ProcessConfirm>
      )}
    </Fragment>
  );
};

export default TicketImportProcessPage;
